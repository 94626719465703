body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F5F4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url('./fonts/ProximaNova/ProximaNova.ttf') format('truetype');
}
.font-face-proxima{
  width: 100%;
  height: 100vh;
  overflow-x:hidden
}
  .signup {
    width: 100%;
    height: 100%;
  }
  