@import 'bootstrap/dist/css/bootstrap.css';
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-face-proxima {
  font-family: 'ProximaNova';
}

.react-icon {
  color: #8b9192;
  cursor: pointer;
  height: 20px;
  font-size: 25px;
}

.react-icon:hover {
  color: #36a49c;
}

.trash-icon:hover {
  color: #be0202;
}

.hyperlink-style {
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  color: #13948b;
  font-weight: 700;
}

.hyperlink-style:hover {
  color: #0e6c66;
}

.hero-icon-primary {
  color: #36a49c;
  cursor: pointer;
  height: 20px;
}

.hero-icon-primary:hover {
  color: #0e6c66;
}

.certify-policy-icon {
  color: #8b9192;
  cursor: pointer;
}

.badge-document {
  cursor: pointer;
}

.back-button {
  border-radius: 100px !important;
  color: black;
  background-color: #fff;
}

.email-link {
  text-underline-offset: 2.5px;
  text-decoration-color: #0c5a55;
}

.required-field::after {
  font-size: 20px;
  content: '*';
  color: red;
}

.add-cursor {
  cursor: pointer;
}

.outline-button {
  color:#000000;
}

.outline-button:hover {
  border-color: #13948b !important;
  border-width: 1.5px !important;
  background-color: #ffffff !important;
  color:  #000000 !important;
}

.arrow-left-icon {
  height: 15px;
  color: '##13948Bb';
  cursor: pointer;
  margin-right: 5px;
}

.clickable-text {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
