// Override default variables before the import
$dark: #0c5a55;
$primary: #0c5a55;
$navbar-light-active-color: #0c5a55;
$tertiary: #ec8046;
$primary-medium: #13948b;
$primary-light: #e1f4ea;
$success-dark: #0e6c66;
$bg-greyish: #3f41c5;
$error-dark: #be0202;
$error-light: #fed7de;
$warning-dark:#e97e01;
$warning-light: #ffefd6;
$primary2-med: #ec8046;
$gray-light3: #f7f5f4;
$gray-medium: #8b9192;
$success-light: #e1f4ea;
// $gray-light1: #D1C7BD;
$gray-light1: #a89f99;
$gray-light2: #EFEBE7;

$font-size-root: 14px;
$badge-font-size: 0.85em;
$badge-padding-x: 1.2em;
$badge-padding-y: 0.45em;
$container-max-widths: (
  sm: 900px,
  md: 980px,
  lg: 1220px,
  xl: 1400px,
  xxl: 1580px,
);

.container {
  border-radius: 20px;
}

$btn-font-size: 16px;
$btn-padding-x: 20px;
$btn-padding-y: 10px;

/* Add a new color to $theme-colors */
$theme-colors: (
  dark: $dark,
  primary: $primary,
  navbar-light-active-color: $navbar-light-active-color,
  tertiary: $tertiary,
  primary-medium: $primary-medium,
  primary-light: $primary-light,
  success-dark: $success-dark,
  bg-greyish: $bg-greyish,
  warning-dark: $warning-dark,
  warning-light: $warning-light,
  error-dark: $error-dark,
  error-light: $error-light,
  primary2-med: $primary2-med,
  gray-light3: $gray-light3,
  gray-medium: $gray-medium,
  success-light: $success-light,
  gray-light1: $gray-light1,
  gray-light2: $gray-light2
);

// $font-family-base: "proxima-nova";

// Import Bootstrap and its default variables
@import '../node_modules/bootstrap/scss/bootstrap';

// border-radius-med: $border-radius-med
